<template>
  <div class="modal modal_wrap" @keyup.esc="closePopup">
    <div class="modal_popup">
      <h2 class="form__title">OOPS! SOMETHING WENT WRONG!</h2>
      <span class="modal__close" @click="closePopup">
         <svg-icon name="times"/>
      </span>

      <p class="text text_green text_italic">Lock cannot be added to the item of a certain size. Please, choose 'No
        Lock' option to proceed.</p>

      <div class="justify-content-between">
        <button class="btn btn_transparent" @click="closePopup">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LockValidations",

    computed: {

    },

    methods: {
      closePopup(){
        this.$emit('accept');
      },


    }
  }
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .btn{
    margin-bottom: 0;
  }
</style>
